import * as React from 'react'
import styled from 'styled-components/macro'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled.div`
  /* position: relative;
  background-color: white; */
  /* margin-left: auto;

  /* max-width: ${getEmSize(widths.lg)}em; */
`

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
)

export default Container
